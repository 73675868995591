.Rsvp {
  margin-top: 50px;
  width: 70%;
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
}

.Rsvp>.title {
  font-family: 'Caveat Brush', cursive;
  font-size: 38px;
}

.Rsvp .content {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}

.Rsvp .input {
  width: 100%;
  text-align: left;
  margin: 10px auto;
  font-size: 18px;
}

.Rsvp .input .label {
  font-weight: bold;
  font-size: 18px;
}

.Rsvp .input input,
.Rsvp .input textarea {
  width: 96%;
  border: none;
  padding: 17px 2%;
  margin: 5px 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}

.Rsvp .input input::placeholder {
  color: #789ADE80;
}

.Rsvp .input.response {
  display: flex;
  flex-direction: row;
}

.Rsvp .input.response .item {
  background-image: url(../../img/radio_off.png), url(../../img/CTA_off.png);
  background-size: 40px 40px, 100% 100%;
  background-position: 20px center, center;
  background-repeat: no-repeat;
  padding: 20px 20px 20px 80px;
  flex: 1;
  margin: 10px;
  font-size: 28px;
  cursor: pointer;
}

.Rsvp .input.response .item>div {
  font-family: 'Caveat Brush', cursive;
  color: black;
}

.Rsvp .input.response .item.selected {
  background-image: url(../../img/radio_on.png), url(../../img/CTA_off.png);
  cursor: pointer;
}

.Rsvp .input.response .item:hover {
  background-image: url(../../img/radio_off.png), url(../../img/CTA_on.png);
}

.Rsvp .input.response .item.selected:hover {
  background-image: url(../../img/radio_on.png), url(../../img/CTA_on.png);
}

.Rsvp .input.count .subcontent {
  display: flex;
  flex-direction: row;
}

.Rsvp .input.count .subcontent .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
}

.Rsvp .input.count .subcontent .item input {
  width: 50px;
  text-align: center;
  margin-right: 10px;
}

.Rsvp .input.hosting {
  border-top: 1px solid #0000001A;
  border-bottom: 1px solid #0000001A;
  margin: 30px 0;
  padding: 30px 0 40px 0;
}

.Rsvp .input.hosting .item {
  background-image: url(../../img/radio_off.png);
  background-size: 40px 40px;
  background-position: 0px 20px;
  background-repeat: no-repeat;
  padding: 20px 20px 20px 60px;
  margin-bottom: 0px;
  cursor: pointer;
}

.Rsvp .input.hosting .item.selected {
  background-image: url(../../img/radio_on.png);
  cursor: pointer;
}

.Rsvp .input.hosting .item .title {
  font-weight: bold;
}

.Rsvp .input.hosting .ps {
  font-style: italic;
  margin-top: 30px;
}

.Rsvp .submit {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  color: white;
  background-color: #789ADE;
  border: none;
  -webkit-border-radius: 80px;
  border-radius: 80px;
  font-weight: bold;
  margin-top: 30px;
  cursor: pointer;
}

.Rsvp .overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
}

.Rsvp.loading .loader,
.Rsvp.sent .sentResponse,
.Rsvp.error .errorContent {
  display: flex;
}

.Rsvp .loader .inner {
  background-image: url(../../img/us.png);
  background-repeat: no-repeat;
  background-size: 200px auto;
  background-position: center;
  animation: tilt-shaking 1s infinite;
  z-index: 10;
}

.Rsvp .overlay .inner {
  max-width: 400px;
  z-index: 10;
  margin: auto;
  position: relative;
  width: 75%;
  padding: 100px 10%;
}

.Rsvp .overlay .inner.content {
  background-image: url(../../img/paint2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.Rsvp .overlay.errorContent .inner.content {
  background-image: url(../../img/paint3.png);
}

.Rsvp .overlay .inner .title {
  font-family: 'Caveat Brush', cursive;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.Rsvp .overlay .inner .message {
  margin-bottom: 20px;
}

.Rsvp .overlay .inner .mails a {
  display: block;
  font-weight: bold;
  color: black;
  word-break: break-all;
}

.Rsvp .overlay .inner .close {
  width: 60px;
  height: 60px;
  top: 50px;
  right: 40px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-image: url(../../img/close.png);
  background-repeat: no-repeat;
  background-position: center;
}

.Rsvp .backDrop {
  width: 100%;
  height: 100%;
  background-color: #000000AA;
  position: absolute;
  z-index: 1;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  8% {
    transform: rotate(5deg);
  }

  16% {
    transform: rotate(0eg);
  }

  24% {
    transform: rotate(-5deg);
  }

  32% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(5deg);
  }

  48% {
    transform: rotate(0eg);
  }

  56% {
    transform: rotate(-5deg);
  }

  64% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 600px) {
  .Rsvp .input.response {
    flex-direction: column;
  }

  .Rsvp .input input,
  .Rsvp .input textarea {
    width: 90%;
    padding: 17px 5%;
  }

  .Rsvp .input.response .item.selected {
    background-image: url(../../img/radio_on.png), url(../../img/CTA_on.png);
    cursor: pointer;
  }
}

@media only screen and (max-width: 450px) {
  .Rsvp {
    width: 85%;
  }

  .Rsvp>.title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 375px) {
  .Rsvp .input.count .subcontent {
    flex-direction: column;
  }

  .Rsvp .input.response .item {
    font-size: 24px;
  }
}