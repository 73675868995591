.Header .topLine {
  display: flex;
  flex-direction: row;
  padding: 30px 10%;
  align-items: center;
}

.Header .topLine>div {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.Header .topLine .left {
  background-image: url(../../img/wedding_txt.png);
  background-size: auto 80%;
  flex: 1;
  height: 200px;
}

.Header .topLine .center {
  background-image: url(../../img/date.png);
  flex: 1.2;
  height: 200px;
}

.Header .topLine .right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 200px;
}

.Header img.us {
  animation: bg-animate 4s infinite;
}

.Header .topLine .right {
  animation: tilt-shaking 1s infinite;
}

.Header .topLine .rsvpButton {
  background-image: url(../../img/CTA_on.png);
  background-size: 100% 100%;
  padding: 20px 30px;
  text-decoration: none;
  color: black;
  font-family: 'Caveat Brush', cursive;
  font-size: 28px;
}

.Header .usContainer {
  margin-top: 110px;
}

.Header .usContainer .us {
  width: 40%;
  margin-right: 8%;
}

@keyframes bg-animate {
  0% {
    margin: 10px 10px 0 10px;
  }

  25% {
    margin: 0 20px 10px 0;
  }

  50% {
    margin: 10px 10px 0 10px;
  }

  75% {
    margin: 0 0 10px 20px;
  }

  100% {
    margin: 10px 10px 0 10px;
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  8% {
    transform: rotate(5deg);
  }

  16% {
    transform: rotate(0eg);
  }

  24% {
    transform: rotate(-5deg);
  }

  32% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(5deg);
  }

  48% {
    transform: rotate(0eg);
  }

  56% {
    transform: rotate(-5deg);
  }

  64% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 1024px) {
  .Header .topLine {
    padding: 30px 10px;
  }

  .Header .topLine .left {
    background-size: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .Header .topLine .left {
    background-size: 90%;
  }

  .Header .topLine .center {
    flex: 1.8;
    background-size: 90%;
  }

  .Header .topLine .right {
    flex: 1;
  }

  .Header .topLine .rsvpButton {
    font-size: 28px;
  }

  .Header .usContainer .us {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .Header .topLine {
    display: block;
    background-color: #B3DFF4;
    padding: 0;
  }

  .Header .topLine>div {
    width: 70%;
    background-position: center;
    margin: auto;
  }

  .Header .topLine .left {
    background-image: url(../../img/wedding_mob.png);
    height: 100px;
    background-size: auto 90%;
  }

  .Header .topLine .center {
    height: 200px;
    background-size: 80%;
  }

  .Header .topLine .right {
    height: 100px;
    justify-content: center;
    font-size: 26px;
  }

  .Header .usContainer {
    margin-top: 70px;
  }

  .Header .usContainer .us {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .Header .topLine .left {
    background-size: 90%;
  }

  .Header .topLine .center {
    background-size: 90%;
  }

  .Header .topLine .right {
    font-size: 20px;

  }
}

@media only screen and (max-width: 350px) {
  .Header .topLine>div {
    width: 90%;
  }

  .Header .topLine .left {
    height: 80px;
    background-size: contain;
  }

  .Header .topLine .center {
    height: 200px;
  }

  .Header .topLine .right {
    font-size: 20px;
    height: 80px;
  }
}