.Infos {
  background-image: url(../../img/paint.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center 20px;
  padding: 60px 30px 50px 30px;
  margin-top: -40px;
}

.Infos .title {
  font-family: 'Caveat Brush', cursive;
  font-size: 38px;
  margin-bottom: 10px;
}

.Infos .content {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}

.Infos .content a{
  color: black;
}

.Infos .content .emphasize {
  font-weight: bold;
}

.Infos .content .subbloc {
  margin-top: 30px;
}

.Infos .content .subbloc .subtitle {
  font-weight: bold;
}

.Infos .content .subbloc ul {
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .Infos {
    background-image: url(../../img/paint2.png);
    background-size: 100% 100%;
    margin: -70px 30px auto 30px;
    padding-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .Infos {
    margin-left: 20px;
    margin-right: 20px;
  }

  .Infos .content .subbloc ul li {
    text-align: left;
  }
}

@media only screen and (max-width: 500px) {
  .Infos .content .subbloc ul {
    align-items: start;
  }
}

@media only screen and (max-width: 450px) {
  .Infos .title {
    font-size: 32px;
  }
}